import React from 'react';
import CSSModules from 'react-css-modules';
import Cookies from 'universal-cookie';

import NavHead from '../NavHead';
import CookieWarning from '../CookieWarning';
import LayoutContainer from '../EditorPanel/ManageSections/LayoutContainer';
import styles from './styles.module.css';

const cookies = new Cookies();

class TabletView extends React.Component {

  constructor(props) {

    super(props);

    cookies.get('cookiesAccepted');

    this.state = {
      matches: null,
      isScrolling: false,
    };

    this.sentinel = React.createRef();

    this.updateMatches = this.updateMatches.bind(this);
    this.documentReady = this.documentReady.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.data[0].fixed_top) {

      const elem = this.sentinel.current;
      if (elem) {

        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);

      }

    }

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  handleScroll(entries) {

    if (this.props.pageContext.navigation.data[0].fixed_top) {

      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });

    }

  }

  documentReady(callbackFunction) {

    if (typeof document !== 'undefined') {

      if (document.readyState !== 'loading') {

        callbackFunction();

      } else {

        document.addEventListener('DOMContentLoaded', callbackFunction);

      }

    }

  }

  render() {

    let ready = false;
    if (typeof document !== 'undefined') {

      this.documentReady(() => {
        ready = true;
      });

    }

    const text = this.props.pageContext.language === 'FI' ? 'Sivua ei löydy' : 'Page not found';

    return (
      <div className="render-wrapper">
        <div ref={this.sentinel} />
        <NavHead isScrolling={this.state.isScrolling} pageContext={this.props.pageContext} />
        <div style={{
          backgroundColor: '#fff',
          width: '100%',
          color: '#444',
          marginTop: '180px',
          fontSize: '30px',
          fontWeight: 600,
          height: '120px',
          textAlign: 'center',
        }}>
          {text}
        </div>
        {
          (
            this.props.pageContext.cookie
            && this.props.pageContext.cookie.active === true
            && cookies
            && cookies.cookies
            && cookies.cookies.cookiesAccepted === undefined
            && ready === true
          )
          && (
            <CookieWarning
              colors={this.props.pageContext.themeData.colors}
              cookie={this.props.pageContext.cookie}
            />
          )
        }
        {
          this.props.pageContext.footer
          && (
            <LayoutContainer
              pageContext={this.props.pageContext}
              key={`Layout_${this.props.pageContext.footer._id}`}
              section={this.props.pageContext.footer}
              matches={this.state.matches}
            />
          )
        }
      </div>
    );

  }

}

export default CSSModules(TabletView, styles, { allowMultiple: true });
